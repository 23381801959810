import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "label" ]

  connect() {
  }

  handleClick(event) {
    this.updateLocaleState();
    switchLanguageofForm(this.labelTarget.dataset.locale);
  }

  updateLocaleState() {
    let newLabel = '';
    let newLocale = '';
    if (this.labelTarget.dataset.locale == 'en') {
      newLocale = 'es';
      newLabel = 'English';
    } else {
      newLocale = 'en';
      newLabel = 'Español';
    }
    this.labelTarget.dataset.locale = newLocale;
    this.labelTarget.innerHTML = newLabel;
  }
}
