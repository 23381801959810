import { Controller } from "stimulus"

export default class extends Controller {

    connect() {
        let _this = this;

        this.modalView = $(this.element).find('.info-modal');

        this.modalView.modal();

        $('.ui.modal').on('touchmove', function(event) {
            event.stopImmediatePropagation();
        });
    }

    show(){
        this.modalView.modal('show');
    }
}
